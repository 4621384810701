import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public host: string;

  constructor() { }

  ngOnInit() {
    this.host = window.location.hostname;
  }

}
